import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/rollback-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/themes/kalimotxo/fonts/fonts.ts\",\"import\":\"Plus_Jakarta_Sans\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"variable\":\"--font-plus-jakarta-sans\",\"adjustFontFallback\":false}],\"variableName\":\"plusJakartaSans\"}");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/rollback-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/themes/daikiri/fonts/fonts.ts\",\"import\":\"Inter\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"variable\":\"--font-inter\",\"adjustFontFallback\":false}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/rollback-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/themes/daikiri/fonts/fonts.ts\",\"import\":\"Arimo\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"weight\":[\"700\"],\"variable\":\"--font-arimo\",\"adjustFontFallback\":false}],\"variableName\":\"arimo\"}");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/rollback-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/themes/mojito/fonts/fonts.ts\",\"import\":\"Nunito_Sans\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"variable\":\"--font-nunito-sans\",\"adjustFontFallback\":false}],\"variableName\":\"nunitoSans\"}");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/rollback-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/themes/mojito/fonts/fonts.ts\",\"import\":\"Poppins\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\",\"italic\"],\"subsets\":[\"latin\"],\"weight\":[\"700\"],\"variable\":\"--font-poppins\",\"adjustFontFallback\":false}],\"variableName\":\"poppins\"}");
;
import(/* webpackMode: "eager" */ "/home/jenkins/workspace/prod/rollback-frontend/node_modules/next/font/google/target.css?{\"path\":\"src/themes/negroni/fonts/fonts.ts\",\"import\":\"Albert_Sans\",\"arguments\":[{\"display\":\"swap\",\"style\":[\"normal\"],\"subsets\":[\"latin\"],\"weight\":[\"400\",\"600\",\"700\"],\"variable\":\"--font-albert\",\"adjustFontFallback\":false}],\"variableName\":\"alber\"}");
;
import(/* webpackMode: "eager", webpackExports: ["LayoutWrapper"] */ "/home/jenkins/workspace/prod/rollback-frontend/src/templates/LayoutWrapper.tsx");
